import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import SEO from 'components/Seo';

const ContactPage = ({ location }) => (
  <Layout>
    <SEO
      keywords={['kopirun', 'delivery', 'food delivery', 'crowdsource', 'contact us']}
      path={location.pathname}
      title="Contact Us"
    />
    <div className="max-w-7xl w-full mx-auto">
      <section className="flex-1 px-4 mt-10 sm:px-6 lg:px-8">
        <h2 className="inline-block mb-4 text-2xl font-bold">Contact Us</h2>

        <div className="flex-1 items-center">
          <p className="mb-4">
            Got any feature requests or bug reports? Or just want to provide feedback or comments in
            general?
          </p>
          <p className="mb-4">
            Shoot us an email:{' '}
            <a
              href="mailto:support@kopirun.com"
              rel="nofollow noopener noreferrer"
              style={{ color: 'blue' }}
              target="_blank">
              support@kopirun.com
            </a>
          </p>
        </div>
      </section>
    </div>
  </Layout>
);

ContactPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default ContactPage;
